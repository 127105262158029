import {Button, toast} from '@cashiaApp/web-components';
import React, {useEffect, useMemo, useState} from 'react';

import FormInput from './FormInput';
import {ReactComponent as DropdownIcon} from '../../../assets/icons/dropdown.svg';
import {ReactComponent as ErrorIcon} from '../../../assets/icons/info-circle.svg';
import DropDown from '../../../components/common/DropDown';
import Spinner from '../../../components/tailwind/Spinner';
import {
  BusinessOnboardingBankAccountInput,
  useGetBanksQuery,
} from '../../../graphql/generated';
import {cn} from '../../../utils/reusableFunctions';
import {useSelfOnboardingContentContext} from '../layout';

const BankInfo = () => {
  const [data, setData] = useState<BusinessOnboardingBankAccountInput>();
  const [AccNoError, setAccNoError] = useState('');
  const [AccHolderNameError, setAccHolderNameError] = useState('');
  const {update, onboardingData, goBack, updateLoading} =
    useSelfOnboardingContentContext();
  const {data: banksData} = useGetBanksQuery();
  const banks = useMemo(() => banksData?.banks, [banksData]);
  const sortedBanks = useMemo(() => {
    const _banks = [...(banks || [])];
    return _banks.sort((a, b) => a.name.localeCompare(b.name));
  }, [banks]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (onboardingData && onboardingData.bankInfo) {
      setData({...onboardingData.bankInfo});
    }
  }, [onboardingData]);

  const handleAccNoChange = (inputValue: string) => {
    if (/^\d*$/.test(inputValue)) {
      setData({...data, accountNumber: inputValue});

      if (inputValue.length < 10) {
        setAccNoError('Account number must be at least 10 digits.');
      } else if (inputValue.length > 14) {
        setAccNoError('Account number must not exceed 14 digits.');
      } else {
        setAccNoError('');
      }
    } else {
      setAccNoError(
        'Please enter a valid Account Number using only numeric characters.'
      );
    }
  };

  const handleAccHolderNameChange = (inputValue: string) => {
    setData({...data, accountHolder: inputValue});

    const validName = /^[A-Za-z\s]*$/;

    if (!validName.test(inputValue)) {
      setAccHolderNameError(
        'Account Holder Name must contain only alphabetic characters.'
      );
      return;
    }

    if (inputValue.length < 5) {
      setAccHolderNameError(
        'Account Holder Name must be at least 5 characters long.'
      );
    } else if (inputValue.length > 100) {
      setAccHolderNameError(
        'Account Holder Name must not exceed 100 characters.'
      );
    } else {
      setAccHolderNameError('');
    }
  };

  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const isDisabled = useMemo(() => {
    return (
      !data?.bankId ||
      !data.accountNumber ||
      !data.accountHolder ||
      !!AccNoError ||
      !!AccHolderNameError
    );
  }, [data, AccNoError, AccHolderNameError]);

  return (
    <div className="flex w-full h-[98%] max-md:h-[93%] overflow-y-scroll">
      <div className="flex flex-col justify-between">
        <div>
          <div>
            <p className="text-black text-sm font-semibold">Step 3:</p>
            <p className="mb-3 font-[600] text-[40px] leading-[40px] max-md:text-[24px] max-md:leading-[24px]">
              Bank Account Details
            </p>
          </div>
          <div className="mb-5">
            <p className="text-sm font-semibold text-black">Bank name *</p>
            <DropDown
              dropDownPosition="center"
              show={dropdownOpen}
              setShow={setDropdownOpen}
              actionElement={
                <div
                  id="bank-name-dropdown"
                  className={`${
                    dropdownOpen ? 'border-red-300' : 'border-dividerGrey'
                  } px-[10px] flex flex-row items-center justify-between max-md:w-[86vw] w-[497px] bg-transparent border-[1px] rounded-[5px] h-[46px] focus:outline-none focus:ring-0 focus:border-red-300`}>
                  <p className="w-[450px] overflow-hidden truncate text-[14px]">
                    {banks?.find((x) => x.id === data?.bankId)?.name || ''}
                  </p>
                  <DropdownIcon />
                </div>
              }>
              <div className="shadow-sm cursor-pointer max-md:w-[88vw] w-[497px] h-[412px] overflow-y-scroll">
                {sortedBanks?.map((item) => (
                  <div
                    id="bank-name"
                    onClick={() => {
                      setData({...data, bankId: item.id});
                      setDropdownOpen(false);
                    }}
                    key={item.name}
                    className="hover:text-red-500 cursor-pointer flex items-center bg-white px-4 py-2 border-b-[1px] border-dividerGrey px-3 py-3">
                    <p className="text-[14px] font-[500]">{item.name}</p>
                  </div>
                ))}
              </div>
            </DropDown>
          </div>
          <FormInput
            label="Account Holder Name"
            required
            error={
              AccHolderNameError ? (
                <div className="flex items-center border rounded-lg p-1 mb-2 bg-lightOrange">
                  <ErrorIcon className="items-center pr-1" />
                  <p className="text-red-500 text-[10px] font-medium items-center ">
                    {AccHolderNameError}
                  </p>
                </div>
              ) : null
            }
            value={data?.accountHolder || ''}
            onChange={handleAccHolderNameChange}
          />
          <FormInput
            label="Account Number"
            required
            error={
              AccNoError ? (
                <div className="flex items-center border rounded-lg p-1 mb-1 bg-lightOrange max-md:mr-[7px]">
                  <ErrorIcon className="items-center pr-1" />
                  <p className="text-red-500 text-[10px] font-medium items-center ">
                    {AccNoError}
                  </p>
                </div>
              ) : null
            }
            value={data?.accountNumber || ''}
            onChange={handleAccNoChange}
          />
        </div>
        <div className="flex flex-row gap-10 w-full pb-6 ">
          <Button
            className="cursor-pointer max-md:w-[143px] w-[237px] h-[48px] border-lightRed border-[1.2px] rounded-[8px] hover:border-brightRed hover:bg-lightPink flex items-center justify-center bg-white"
            onClick={goBack}>
            <p className="text-active-red font-[600]">Previous</p>
          </Button>
          <Button
            className={cn(
              'max-md:w-[143px] w-[237px] h-[48px] rounded-[8px] flex items-center justify-center font-[600] bg-pink text-white',
              {
                'text-disabledText bg-lightGrey cursor-default': isDisabled,
                ' hover:bg-hotPink': !isDisabled,
              }
            )}
            onClick={() => {
              if (isDisabled) return;
              update?.({...onboardingData, bankInfo: {...data}});
              toast.success('Bank account info captured!');
            }}>
            {updateLoading ? <Spinner className="fill-white" /> : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BankInfo;
