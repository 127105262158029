import {Button, useMediaQuery} from '@cashiaApp/web-components';
import {format} from 'date-fns';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Activation from './components/activation';
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow-right.svg';
import {ReactComponent as DownArrowIcon} from '../../assets/icons/down-arrow.svg';
import {ReactComponent as Pluscon} from '../../assets/icons/plus.svg';
import {ReactComponent as ActiveIcon} from '../../assets/icons/row-link-active.svg';
import CustomSpinner from '../../components/common/CustomSpinner';
import CommonTable from '../../components/common/table';
import {useGetPaymentLinksQuery} from '../../graphql/generated';
import {cn} from '../../utils/reusableFunctions';
import {useUserAuth} from '../../utils/user';

type RowData = {
  id: string;
  Title: {
    image: JSX.Element;
    text: string;
  };
  'Date created': string;
  status: JSX.Element;
  amount: string;
};

const Home = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const {business, businessOnboarding} = useUserAuth();

  const [data, setData] = useState<RowData[]>([]);
  const {data: linksData, loading: linksLoading} = useGetPaymentLinksQuery({
    variables: {
      input: {
        cursor: {
          first: 5,
        },
      },
    },
  });

  useEffect(() => {
    if (!linksData?.paymentLinks?.edges) return;
    setData(
      linksData?.paymentLinks?.edges?.map((edge) => ({
        id: edge.node.id,
        Title: {
          text: edge.node.title,
          image: <ActiveIcon />,
        },
        'Date created': format(
          new Date((edge.node.createdAt as string) || 0),
          'do MMM, yyyy'
        ),
        amount:
          edge.node.cost.currencyCode +
          ' ' +
          new Intl.NumberFormat('en-US').format(
            Number(edge.node.cost.amountInCents / 1000)
          ),
        status: (
          <div>
            <p
              className={cn('text-[#E62229]', {
                'text-green-400': edge.node.active,
              })}>
              {edge.node.active ? 'Active' : 'Deactivated'}
            </p>
          </div>
        ),
      }))
    );
  }, [linksData]);

  const navToLinkDetails = (row: {id?: string}) =>
    navigate('/payment-links/link-details', {state: {link: row.id}});

  return (
    <div className="w-full h-full overflow-y-scroll sm:py-5 lg:py-8 pb-10">
      {(business?.owner.firstName || businessOnboarding?.ownerFirstName) && (
        <h2 className="sm:flex lg:hidden mb-7 capitalize font-semibold text-2xl">
          Hi {business?.owner.firstName || businessOnboarding?.ownerFirstName}
        </h2>
      )}
      <div className="flex w-full flex-col gap-5">
        <Activation
          business={business}
          businessOnboarding={businessOnboarding}
        />
        <div className="w-full h-[1px] bg-[#DAD8D8]" />
        <div className="flex flex-col gap-4">
          <div className="sm:hidden lg:flex items-center gap-[6px]">
            <h3 className="font-semibold text-[20px]">Today</h3>
            <DownArrowIcon />
          </div>
          <div className="flex flex-col w-full gap-9 pb-8 border rounded-xl">
            <div className="flex w-full justify-between items-start px-5 pt-5">
              <div className="flex flex-col">
                <p className="font-medium text-[15px] text-foggy">Revenue</p>
                <p className="font-bold text-[24px]">KES 0.00</p>
              </div>
              <div className="sm:flex lg:hidden items-center gap-1">
                <h3 className="font-medium text-[15px]">Today</h3>
                <DownArrowIcon />
              </div>
            </div>
            <div className="sm:hidden lg:flex w-full flex-col gap-8">
              {Array.from({length: 5}, (_, idx) => (
                <div
                  key={idx}
                  className="w-full h-[1px] border-dashed border border-[#E1E3E8]"
                />
              ))}
            </div>
          </div>
        </div>
        {(linksData?.paymentLinks?.pageInfo?.entries || 0) === 0 ? (
          <>
            <div className="w-full h-[1px] bg-[#DAD8D8]" />
            <div className="sm:space-y-[10px] lg:space-y-7">
              <div className="sm:space-y-1 lg:space-y-0">
                <h3 className="sm:font-semibold lg:font-bold sm:text-[20px] lg:text-[34px]">
                  Create your first payment link
                </h3>
                <p className="font-medium sm:text-[15px] lg:text-[16px]">
                  Sell products, offer subscriptions, or accept payments with a
                  link—no code required.
                </p>
              </div>
              <Button
                variant={isDesktop ? undefined : 'outline'}
                className={cn(
                  'flex active:scale-95 sm:justify-start lg:justify-center gap-2 border-smoothRed hover:border-smoothRed sm:w-full lg:w-[255px] sm:h-[66px] lg:h-[48px] sm:py-0 lg:py-4 sm:px-4 lg:px-0 font-medium text-[15px]',
                  {'bg-smoothRed': isDesktop}
                )}
                onClick={() => navigate('/payment-links/create-link')}>
                <div
                  className={cn(
                    'sm:flex lg:hidden w-9 h-9 shrink-0 rounded-full justify-center items-center bg-smoothRed'
                  )}>
                  <Pluscon className="w-4 h-4" />
                </div>
                <span>Create your first payment link {isDesktop && `+`}</span>
              </Button>
            </div>
          </>
        ) : (
          <div className="overflow-y-scroll h-full flex-grow flex flex-col overflow-auto">
            <div className="w-full flex justify-between items-center my-8 max-md:my-5">
              <p className="font-[700] text-[34px] max-md:text-[20px]">
                Payment links
              </p>
              <div className="flex gap-x-6 items-center ">
                <Button
                  onClick={() => navigate('/payment-links/create-link')}
                  className="max-md:bottom-[20px] max-md:w-[92vw] w-[196px] max-md:rounded-[24px] max-md:text-[13px] max-md:h-[30px] h-[33px] bg-pink">
                  {isDesktop ? 'Create a payment link +' : 'Add +'}
                </Button>
                <p
                  onClick={() => navigate('/payment-links')}
                  className="border-none underline text-cashiaBlue items-center text-sm font-normal hover:cursor-pointer">
                  View All
                </p>
              </div>
            </div>
            {linksLoading && <CustomSpinner />}
            {isDesktop ? (
              <div>
                <CommonTable
                  columns={['Title', 'Date created', 'amount', 'status']}
                  data={data}
                  onSelect={navToLinkDetails}
                  hideHeaders={true}
                />
              </div>
            ) : (
              <div>
                {data.map((item) => (
                  <div
                    key={item.id}
                    onClick={() => navToLinkDetails(item)}
                    className="flex items-center justify-between border-b-[1px] border-greyish py-[10px] w-full">
                    <div className="flex items-center gap-5 flex-1 min-w-0">
                      <ActiveIcon className="min-w-[34px]" />
                      <div className="min-w-0">
                        <p className="text-[14px] font-[500] break-words overflow-hidden text-ellipsis whitespace-nowrap">
                          {item.Title.text}
                        </p>
                        <p className="text-foggy flex gap-1 text-[13px]">
                          {item.amount} -
                          <span className="text-green-400 font-[500]">
                            {item.status}
                          </span>
                        </p>
                      </div>
                    </div>
                    <ArrowIcon />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
